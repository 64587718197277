@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./gridlayout.css";

@import "../common/src/styles/global.css";
@import "../common/src/styles/themes/zinc.css";
@import "../common/src/styles/typography.css";
@import "../common/src/styles/common.css";
@import "../common/src/styles/components.css";

/* external libraries 👇🏽 */
@import "../common/src/styles/lib/nprogress.css";
@import "../common/src/styles/lib/rheostat.css";
@import "../common/src/styles/lib/radio.css";
@import "../common/src/styles/lib/highcharts.css";
@import "../common/src/styles/lib/datePicker.css";
@import "../common/src/styles/lib/colorInput.css";
@import "../common/src/editor/css/editorDefaults.css";
@import "../common/src/editor/css/fullpage.css";

/*@import 'react-date-range/dist/theme/default.css'; 
 * Theme css has been overridden in reactDateRangesOverride
 * */
/* order needs to be maintained */
@import "react-date-range/dist/styles.css"; /* imp  */
@import "../common/src/styles/lib/reactDateRangeOverrides.css";
